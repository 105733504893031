html {
    scroll-behavior: smooth;
}

.leaflet-container {
    width: 100 wh;
    height: 400px;
    z-index: 0;
}

.register-header {
    padding-top: 20px;
    text-align: center;
    color: #fff;
    z-index: 1000;
}

.register-header h1 {
    font-size: 40px;
    font-weight: bold;
    padding-bottom: 20px;
}

.register-header h3 {
    font-size: 20px;
    font-weight: bold;
    padding: 10px 0 30px 0;
}

.logo_img {
    max-width: 100%;
    height: auto;
    width: 14rem;
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.wizard_btn {
    font-weight: 500;
    padding: 10px 20px;
    border-radius: 5px;
}

.back_btn {
    border: 1px solid #28c76f;
    color: #28c76f;
}

.next_btn {
    color: #fff;
    background: #28c76f;
    float: right;
}

.preview_btn {
    color: #fff;
    background: #0EA5E9;
    float: right;
}


/* register and login page */

.bgGray {
    background: #4B5462;
}

.blueBg {
    background: #0057B7;
}

.blueText {
    color: #0057B7;
}

.blueTextDark {
    color: #011e77;
}

.blueDarkBg {
    background: #011e77;
}

.blueDarkBgBorder {
    border: 1px solid #011e77;
}

.blueBorder {
    border: 1px solid #0057B7;
}

.textOrange {
    color: #F24822;
}

.bgOrange {
    background: #F24822;
}

.borderOrange {
    border-color: #F24822;
}


.circle {
    border-radius: 50%;
    width: 34px;
    height: 34px;
    text-align: center;
}

.invalid {
    align-items: center;
}

.invalid, .valid {
    align-items: center !important;
}

.circle_3::after {
    content: "";
    width: 82px;
    height: 100px;
    background: #0284c7;
    height: 22px;
    position: absolute;
    left: 48px;
    top: 14px;
}

iframe {
    display: none !important;
}

.scrollable-div {
    -ms-overflow-style: none; /* Remove scrollbar in IE and Edge */
    /* Firefox */
    scrollbar-width: thin;
    scrollbar-color: #075985 lightgray;
}

.scrollable-div.scroll-left-side {
    direction: rtl;
}

.scrollable-div.scroll-left-side > * {
    direction: ltr;
}

.scrollable-div::-webkit-scrollbar {
    width: 6px;
    border-radius: 6px;
}

.scrollable-div::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #075985;
}

.scrollable-div::-webkit-scrollbar-button {
    width: 6px;
    height: 100%;
}

.scrollable-div::-webkit-scrollbar-track {
    background: lightgray;
    border-radius: 6px;
}

.extertnal-checkbox {
    background: transparent;
    border: 2px solid #404040;
    border-radius: 3px;
    padding: 1px;
    width: 12px;
    height: 12px;
    display: flex;
    flex-shrink: 0;
}

.extertnal-checkbox.checked {
    border: 2px solid white;
}

.checked-checkbox {
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 1px;
}

.scrollbox {
    height: 100% !important;
    width: 100% !important;
    background: linear-gradient(#fafafa 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), #fafafa 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: #fafafa;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    background-attachment: local, local, scroll, scroll;
}

/*.bg-blue-50 {*/
/*    background-color: #294e86;*/
/*}*/

/*.bg-blueGray-100 {*/
/*    background-color: #6B7280;*/
/*}*/

/* Barre du slider */
.input-range {
    --percent: 50%;
    background-color: #888888;
    background-image: linear-gradient(#075985, #075985);
    background-size: var(--percent) 100%;
    background-repeat: no-repeat;
    border-radius: 5px;

    display: block;
    width: 100%;
    height: 4px;
    -webkit-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
}

/* Curseur du slider */
.input-range::-webkit-slider-thumb {
    height: 12px;
    width: 12px;
    border: 0;
    border-radius: 100%;
    outline: rgba(136, 136, 136, 0.51) solid 1px;
    background: #075985;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
}

.input-range::-webkit-slider-thumb {
    outline-color: rgba(136, 136, 136, 0.52);
    background: #075985;
}

.input-range::-moz-range-thumb {
    height: 12px;
    width: 12px;
    border: 0;
    border-radius: 100%;
    outline: rgba(136, 136, 136, 0.46) solid 1px;
    background: #075985;
    cursor: pointer;
    appearance: none;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px
}

::-webkit-scrollbar-button {
    width: 0;
    height: 0
}

::-webkit-scrollbar-thumb {
    background: #075985;
    border: 0 none #fff;
    border-radius: 0
}

::-webkit-scrollbar-thumb:hover {
    background: #2b2b2b
}

::-webkit-scrollbar-thumb:active {
    background: #075985
}

::-webkit-scrollbar-track {
    background: #fff;
    border: 0 none #fff;
    border-radius: 0
}

::-webkit-scrollbar-track:hover {
    background: #ccc
}

::-webkit-scrollbar-track:active {
    background: #fff
}

::-webkit-scrollbar-corner {
    background: 0 0
}

html {
    overflow-y: scroll;
    -ms-overflow-style: scrollbar
}
canvas {
    max-width: 100%;
}


