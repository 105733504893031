
#form-file-upload {
    height: 16rem;
    width: 100%;
    max-width: 100%;
    text-align: center;
    position: relative;
}

#input-file-upload {
    display: none;
}

#label-file-upload.upload-done {
    background: #ecf3f2;
}

#label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-duration: 300ms;
}

#label-file-upload:hover {
    border-color: #6B7280
}

#label-file-upload.upload-done:hover{
    border-color: #cbd5e1;
}

#label-file-upload.drag-active {
    background-color: #ffffff;
}

.upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1.25rem;
    border: none;
    font-family: 'Oswald', sans-serif;
    background-color: transparent;
}

.upload-button:hover {
    text-decoration-line: underline;
}

#drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}